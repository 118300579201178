import CTAPage from "components/landing-pages/CTA/CTAPage";
import { useGetQuoteTitleAtom } from "components/landing-pages/surveillance/SurveillancePageV4";

import { graphql, StaticQuery } from "gatsby";
import { useAtomValue } from "jotai";
import React from "react";

const backLink = "/lp/cameras-c/";

export default function CTAPage1() {
  const useGetQuoteTitle = useAtomValue(useGetQuoteTitleAtom);
  const formTitle = useGetQuoteTitle
    ? "Start Quote Now"
    : "See Rhombus in Action";
  const renderContent = data => {
    return (
      <CTAPage
        image={data.heroImage}
        title="Automate Surveillance of Everything"
        blurb="Monitor every angle, automatically identify security events, and resolve incidents in real time with Rhombus' AI and cloud-based solution."
        formId="6b9307b5-fde3-45ef-b77a-e59136b4a147"
        formTitle={formTitle}
        formBlurb="Consult with Rhombus on a seamless surveillance solution."
        formLastStepTitle="Just Need a Little More Info"
        formLastStepText="Last step."
        successTitle={"Thank You"}
        successMessage={"Someone one from Rhombus will reach out soon."}
        backLink={backLink}
      />
    );
  };
  const GET_IMAGES = graphql`
    query {
      heroImage: file(
        relativePath: {
          eq: "components/landing-pages/CTA/assets/CTA-1-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
